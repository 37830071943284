<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex>
        <v-card elevation="4" width="1000" class="mx-auto">
          <v-card-title primary-title>
            <h4 class="headline mb-0" style="color:gray">
              <b>{{ title }}</b>
            </h4>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="بحث"
              single-line
              hide-details
            >
            </v-text-field>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :search="search"
              :loading="loading"
              :headers="headers"
              :items="visits"
              sort-by="name"
              class="elevation-1 mt-3"
            >
              <template v-slot:top>
                <v-dialog v-model="dialogDelete" max-width="300px">
                  <v-card>
                    <v-card-title class="headline"
                      >هل أنت متأكد من الحذف؟</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text-color="white"
                        text
                        @click="closeDelete"
                        >إلغاء</v-btn
                      >
                      <v-btn
                        color="gray"
                        text-color="white"
                        text
                        @click="deleteItemConfirm"
                        >موافق</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <!-- <template v-slot:[`item.task.name`]="{ item }">
                {{ item.task.name }}
              </template>-->
              <template v-slot:[`item.task.typeId`]="{ item }">
                {{
                  item.task.typeId == 1
                    ? "زيارة"
                    : item.task.typeId == 2
                    ? "تنبيه"
                    : item.task.typeId == 3
                    ? "طلب معلومات "
                    : "-"
                }}
              </template>
              <template v-slot:[`item.task.startDate`]="{ item }">
                {{ createdDate(item.task.startDate) }}
              </template>
              <template v-slot:[`item.task.endDate`]="{ item }">
                {{ createdDate(item.task.endDate) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  color="green"
                  text-color="white"
                  small
                  class="mr-2"
                  @click="
                    $router.push({
                      name: 'show-visit',
                      params: { visitId: item.idvisit, visit: item }
                    })
                  "
                >
                  mdi-eye
                </v-icon>
                <v-icon
                  color="blue"
                  v-if="role == 'Admin'"
                  text-color="white"
                  small
                  class="mr-2"
                  @click="
                    $router.push({
                      name: 'edit-task',
                      params: { taskId: item.task.idtask, task: item }
                    })
                  "
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  color="red"
                  v-if="role == 'Admin'"
                  text-color="white"
                  small
                  @click="deleteItem(item.idtask)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                لا توجد بيانات
              </template>
            </v-data-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="$router.go(-1)" style="background:gray;" dark>
              <b>رجوع</b>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      dialogDelete: false,
      headers: [
        {
          text: "رقم الزيارة",
          align: "center",
          sortable: true,
          value: "idvisit"
        },
        {
          text: "اسم المهمة",
          align: "center",
          sortable: true,
          value: "task.name"
        },
        {
          text: "نوع المهمة",
          align: "center",
          sortable: true,
          value: "task.typeId"
        },
        {
          text: "تاريخ البداية",
          align: "center",
          sortable: true,
          value: "task.startDate"
        },
        {
          text: "تاريخ النهاية",
          align: "center",
          sortable: false,
          value: "task.endDate"
        },
        { text: "خيارات", align: "center", value: "actions", sortable: false }
      ],
      title: "",
      StartDate: "",
      endDate: "",
      loading: false,
      selectedTaskType: "",
      selectedTaskStatus: "",
      visits: [],
      externalType: "",
      page: 1,
      totalItems: 0,
      type: 1,
      dialog: false,
      taskDelete: 0
    };
  },
  methods: {
    deleteItem(item) {
      this.deletedItem = item;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await this.ApiService.delete("task/DeleteTask/" + this.deletedItem)
        .then(() => {
          this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
          this.getAllVisits();
        })
        .catch(() => {
          this.hel.showSnackMsg("فشلت العملية", "#ff0000", 2000);
        });
      this.closeDelete();
    },
    closeDelete() {
      this.deletedItem = null;
      this.dialogDelete = false;
    },
    createdDate(date) {
      const current_datetime = new Date(date);
      return current_datetime.toLocaleDateString("en-US");
    },
    async getAllVisits() {
      let url = null;
      if (this.$route.params.type == "new-visits") {
        url = "visit/NewVisitsByUser?user=" + this.user.id;
        this.title = "كل الزيارات الموكلة";
      }
      if (this.$route.params.type == "archive-visits") {
        url = "visit/ArchiveVisitsByUser?user=" + this.user.id;
        this.title = "كل الزيارات المنجزة";
      }
      if (this.$route.params.type == "waiting-review-visits") {
        url = "visit/WaitingReviewVisitsByUser?user=" + this.user.id;
        this.title = "كل الزيارات بانتظار المراجعة";
      }
      if (this.$route.params.type == "doing-task-visits") {
        url = "visit/DoingVisitsByUser?user=" + this.user.id;
        this.title = "كل الزيارات قيد التقدم";
      }
      this.loading = true;
      await this.ApiService.get(url)
        .then(res => {
          this.visits = [];
          res.data.responseData.forEach(el => {
            if (el != null) {
              this.visits.push(el);
            }
          });
        })
        .catch(() => {});
      this.loading = false;
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.getAllVisits();
  }
};
</script>
<style lang="scss"></style>
